.widget-photoalbum h2 {
  margin-bottom: 20px;
}
.widget-photoalbum__container {
  position: relative;
  /*
  16:9 aspect:
  height = 56.25% of total width

  right width 3x squares:
  56.25% / 3 = 18.75%;

  preview width: 100 - 18.75%;
  */
}
.widget-photoalbum__container::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-photoalbum__container__preview {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 81.25%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.widget-photoalbum__container__preview::after {
  content: "";
  display: block;
  position: absolute;
  right: -2px;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #fff;
}
.widget-photoalbum__container__preview__title {
  color: #fff;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 15px;
  line-height: 22px;
  padding: 15px 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  min-height: 50%;
  max-height: 100%;
}
.widget-photoalbum__container__rightcol {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 18.75%;
  overflow: hidden;
}
.widget-photoalbum__container__rightcol__thumb {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.widget-photoalbum__container__rightcol__thumb::after {
  vertical-align: bottom;
  content: "";
  display: block;
  padding-top: 100%;
}
.widget-photoalbum__container__rightcol__thumb + .widget-photoalbum__container__rightcol__thumb::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -2px;
  width: 100%;
  height: 4px;
  background-color: #fff;
}
.widget-photoalbum__count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
  height: 33.3333%;
  text-align: center;
  width: 18.75%;
  color: #283477;
  cursor: pointer;
}
.widget-photoalbum__count > span {
  width: 74px;
  height: 74px;
  margin-top: 3px;
  display: inline-block;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  font: bold 40px/70px "Faustina", serif, Arial;
}
@media (max-width: 600px) {
  .widget-photoalbum__container__preview__title {
    font-size: 13px;
    line-height: 16px;
    padding: 10px;
  }
  .widget-photoalbum__count > span {
    width: 11.65vw;
    height: 11.65vw;
    font-size: 6.65vw;
    line-height: 11vw;
  }
}