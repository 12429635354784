@charset "UTF-8";
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.wh-form__label {
  min-width: 200px;
}

.wh-form__imgedit {
  width: 250px;
  height: 250px;
}

/* Defaults */
html {
  color: #3B3B3B;
  font: 12px Arial, Sans-Serif;
}

body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: #e35725;
}

p {
  margin: 0px;
  padding: 0 0 10px 0;
}

h4 {
  font: 18px Georgia;
  color: #db420c;
  height: 30px;
}

h2 {
  margin: 0;
  padding: 10px 0 0 0;
  font: italic 110% Georgia;
  color: #db420c;
}

#mobilelogoholder {
  display: none;
}

header {
  position: relative;
  width: 100%;
}

header:after {
  content: "";
  padding-top: 25.6%;
  display: block;
}

#headerslideshow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#headerslideshow .wh-slideshow-item {
  width: 100%;
  height: 100%;
}

#headerslideshow .wh-slideshow-item img {
  width: 100%;
  height: auto;
}

.righthr {
  height: 1px;
  background: #5c5b5b;
  margin-bottom: 20px;
  margin-top: 20px;
}

#menubar {
  width: 100%;
  background: #b0b0b0;
  background: -ms-linear-gradient(top, #ddd9da 0%, #b0b0b0 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ddd9da 0%, #b0b0b0 100%); /* W3C */
  border-top: 1px solid #8b8b8b;
  border-bottom: 1px solid #8b8b8b;
  height: 52px;
}

#menubar nav {
  padding-left: 279px;
}

#menubar nav li {
  list-style: none;
  display: inline-block;
  font: 16px/50px "Helvetica Neue", Helvetica, Arial;
  margin: 0 20px;
}

#menubar nav li a {
  color: #3b3b4e;
}

#menubar nav li a:hover {
  color: #e35725;
}

#contentholder {
  width: 100%;
  background: #922e0a;
  background: -ms-linear-gradient(top, #e35725 0%, #922e0a 100%); /* IE10+ */
  background: linear-gradient(to bottom, #e35725 0%, #922e0a 100%); /* W3C */
}

#contentcenter {
  position: relative;
  background: #ffffff;
  padding: 0 0 41px;
  /*padding:20px 12px 41px 279px;*/
}

#contentcenter > * {
  float: left;
}

#contentcenter:after {
  content: "";
  display: block;
  clear: both;
}

div#submenuholder {
  position: relative;
  top: -130px;
  margin-left: 13px;
}

div#submenu {
  border: 4px solid #e35725;
  border-radius: 10px;
  background: #ffcc99;
  width: 252px;
  padding: 15px;
  overflow: hidden;
  line-height: 150%;
  min-height: 300px;
}

div#submenu h3 ~ h3 {
  margin-top: 15px;
}

div#submenu h3:after {
  display: block;
  content: "";
  width: 213px;
  height: 5px;
  margin: 2px 0 2px;
  background: url(../web/img/line.png) no-repeat;
}

div#submenu h3 {
  font: 18px Georgia;
  height: 21px;
  margin: 0 0 15px 0;
}

div#submenu h2 {
  color: #3b3b3b;
  margin-bottom: 8px;
  font: italic 125% Georgia;
}

div#submenu ul {
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
}

div#submenu li {
  background: url(../web/img/ls.png) no-repeat left center;
  padding-left: 50px;
  padding-top: 4px;
  margin-bottom: 8px;
}

div#submenu li.cam {
  background-image: url(../web/img/cam.png);
}

#logoholder {
  position: absolute;
  top: -163px;
  left: -15px;
}

#iconsholder {
  position: absolute;
  right: 10px;
  top: -40px;
}

#fbicon, #langicon {
  width: 32px;
}

#langicon {
  margin-left: 4px;
}

main {
  max-width: 527px;
  margin-left: 13px;
  margin-top: 20px;
  width: calc(100% - 252px);
}

main ul {
  padding: 0 10px 10px 25px;
  margin: 0;
}

main:after {
  content: "";
  display: block;
  clear: both;
}

.wh-imagegallery {
  max-height: 400px;
}

.wh-imagegallery-preview .wh-imagegallery-scroller-content > .thumb {
  width: 200px;
}

.wh-imagegallery-preview .wh-imagegallery-scroller-content > .thumb img {
  max-width: none;
}

.wh-imagegallery-preview .infobar {
  background-color: #e35725;
  color: #fff;
  height: 30px;
  text-align: center;
  line-height: 30px;
  white-space: nowrap;
}

.wh-imagegallery-preview .infobar .counter,
.wh-imagegallery-preview .infobar .info {
  display: inline-block;
}

.wh-imagegallery-preview .wh-slideshow-item {
  margin-top: 30px;
}

.wh-imagegallery-grid .wh-slideshow {
  background-color: transparent;
}

.wh-imagegallery .previous,
.wh-imagegallery-preview .previous,
.wh-imagegallery .next,
.wh-imagegallery-preview .next {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 12px 20px;
  width: 65px;
  height: 65px;
  border-radius: 10px;
}

img {
  border-style: none;
}

img.centeredimage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.leftimage {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

img.rightimage {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

img.camera {
  width: 526px;
}

footer {
  width: 100%;
  background: #e35725;
  min-height: 48px;
  padding-top: 17px;
  color: #ffffff;
  font: 13px Georgia;
}

footer a > * {
  color: inherit;
  vertical-align: middle;
}

footer a:hover {
  color: inherit;
}

#footerlinks {
  position: absolute;
  top: 0;
  right: 0;
}

#footerlinks li {
  list-style: none;
  display: inline-block;
}

.centerit {
  position: relative;
  width: 818px;
  margin: 0 auto;
}

table {
  padding-left: 10px;
  padding-right: 10px;
}

.ferretprofile {
  width: 120px;
}

.informationtable, .ainsleytable {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border-collapse: collapse;
}

.reservationtable {
  width: 148%;
  margin-left: -260px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}

.reservationtable table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}

.ainsleytable th {
  text-align: left;
}

.informationtable th, .reservationtable th {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.1em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  padding-left: 5px;
  background-color: #DB420C;
  color: #ffffff;
}

.informationtable tr.alt td, .reservationtable tr.alt td {
  background-color: #fff7de;
}

.informationtable td, .informationtable th, .reservationtable td, .reservationtable th {
  font-size: 1em;
  border: 1px solid #E35725;
  padding: 3px 7px 2px 7px;
}

.ainsleytable td, .ainsleytable th {
  font-size: 1em;
  border: 1px solid #b3b3b3;
  padding: 3px 7px 2px 7px;
}

.whwp-question {
  margin-bottom: 10px;
}

.whwp-title {
  font: italic 110% Georgia;
  color: #db420c;
  margin: 10px 0 10px 0;
}

@media (max-width: 817px) {
  #mobilelogoholder {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
  }
  #mobilelogoholder img {
    max-width: 50%;
    width: auto;
    height: auto;
  }
  #logoholder {
    display: none;
  }
  #contentcenter > * {
    float: none;
  }
  #menubar {
    height: auto;
  }
  #menubar nav {
    padding: 0 5px;
  }
  div#submenuholder {
    position: static;
    margin: 20px;
  }
  div#submenu {
    width: auto;
    min-height: 0;
  }
  .centerit {
    width: auto;
  }
  main {
    width: auto;
  }
  main img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  footer {
    padding: 5px;
  }
  #footerlinks {
    position: static;
  }
  #footerlinks a {
    display: block;
    padding: 5px 0;
  }
  .reservationtable {
    width: auto;
    max-width: 100%;
    margin: 0;
    overflow-x: auto;
  }
}