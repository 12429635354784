  .wh-photogrid
, .wh-photogrid .wh-slideshow
{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wh-photogrid .wh-slideshow-item
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wh-photogrid .wh-slideshow
{
  border: 0 none;
}
.wh-photogrid > .wh-photogrid-item
{
  visibility: hidden;/* not visible if outside slide */
}
.wh-photogrid.noslideshow > .wh-photogrid-item
{
  visibility: visible;
  overflow: hidden;
}
.wh-photogrid-item
{
  text-decoration: none;
  overflow: hidden;
  float: left;
  display: inline-block;
  position: absolute;
  cursor: pointer;

  transition: all 0.4s;
  background-color: #fff;
  background: transparent none 50% 50% no-repeat;
}
.wh-photogrid .wh-photogrid-item:hover
{
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
  z-index: 1;/* set on top of other thumbs */
  transform: scale(1.2);
}
.wh-photogrid .wh-photogrid-item img
{
  width: auto;
  min-width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: -9999px;/* center image */
  right: -9999px;
}
  .wh-photogrid .previous
, .wh-photogrid .next
{
  position: absolute;
  top: 50%;
  margin-top: -20px;
  cursor: default;
  opacity: 1;
  z-index: 1;/* stay on top of hovered thumb */
  width: 25px;
  height: 40px;
  opacity: 0;
  display: inline-block;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
  .wh-photogrid .previous.enabled
, .wh-photogrid .next.enabled
{
  cursor: pointer;
  opacity: 1;
}
.wh-photogrid .previous
{
  border-right: 20px solid #fff;
  left: 20px;
  transition: left 0.3s, opacity 0.3s;
}
.wh-photogrid .previous.enabled:hover
{
  left: 17px;
}
.wh-photogrid .next
{
  border-left: 20px solid #fff;
  right: 20px;
  transition: right 0.3s, opacity 0.3s;
}
.wh-photogrid .next.enabled:hover
{
  right: 17px;
}


/* force hardware acceleration */
  .wh-photogrid-item
, .wh-photogrid .previous
, .wh-photogrid .next
{
  -webkit-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
