.widget-imagetiles {
  position: relative;
  z-index: 0;
}
.widget-imagetiles__scrollcontainer {
  overflow-x: auto;
}
.widget-imagetiles__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.widget-imagetiles__tile {
  flex: 0 1 auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  transition: transform 0.2s;
  cursor: pointer;
}
.widget-imagetiles__tile:hover {
  z-index: 1;
  transform: scale(1.05, 1.05);
}
.widget-imagetiles__tile img {
  vertical-align: bottom;
  position: relative;
  height: 100%;
  max-width: none;
}